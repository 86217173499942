import {httpApiV3, useVzFetch} from '~/composables/useVzFetch';
import {
  TradingNetwork,
  TradingNetworkAddress,
  TradingNetworkListAddressRequest,
  TradingNetworkListRequest
} from '~/types/types';

export function getTradingNetworkAddressList(params: TradingNetworkListAddressRequest, signal: AbortSignal|undefined = undefined) {
  return useVzFetch<TradingNetworkAddress[]>(
      `${httpApiV3}/order/trading-network/address`,
      { params, signal }
  )
}

export function getTradingNetworkList(params: TradingNetworkListRequest, signal: AbortSignal|undefined = undefined) {
  // При вводе цифр будет поиск по инн, если строка то по имени
  return useVzFetch<TradingNetwork[]>(
      `${httpApiV3}/order/trading-network`,
      { params: { ...params, defaultPerPage: 0 }, signal }
  )
}
